import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClientHelperService } from "../core/Http/http-client-helper.service";

import { CitGlobalContantsService } from "../core/globalUrl/cit-global-contants.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  constructor(
    private httpClient: HttpClientHelperService,
    private globalConst: CitGlobalContantsService
  ) {}

  searchType() {}

  qrDownload(name){
    return this.globalConst.qrDownload + '/tables/qr/' + name;
  }

  getmenusList(page, query, type) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.menusList + "?page=" + page  + '&menu_type=' + type
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.menusList + "?search=" + query + "&page=" + page + '&menu_type=' + type
      );
    }
  }

  getmenusListFilter(id, page,type) {
    return this.httpClient.getRequest(
      this.globalConst.menusList + "?category_id=" + id + "&page=" + page + '&menu_type=' + type
    );
  }

  getMenuById(id) {
    return this.httpClient.getRequest(this.globalConst.menusList + "/" + id);
  }

  getMenuDropDown(data) {
    return this.httpClient.getRequest(this.globalConst.menusList + "/dropdown?categories[]=" + data.join('&categories[]='));
  }

  getComboMenuDropDown() {
    return this.httpClient.getRequest(this.globalConst.menusList + "/combo-dropdown");
  }

  menuCreate(data) {
    return this.httpClient.postRequest(this.globalConst.menusList, data);
  }

  menuUpdate(menu_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.menusList + "/" + menu_id,
      data
    );
  }

  menuStatusUpdate(menu_id, data) {
    return this.httpClient.patchRequest(
      this.globalConst.menusList + "/" + menu_id,
      data
    );
  }

  menuDelete(menu_id) {
    return this.httpClient.deleteRequest(
      this.globalConst.menusList + "/" + menu_id,
      {}
    );
  }

  getCategories(page, query) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.categories + "?page=" + page
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.categories + "?search=" + query + "&page=" + page
      );
    }
  }

  getAllCategories() {
    return this.httpClient.getRequest(
      this.globalConst.categories + "/dropdown"
    );
  }

  getCategoryById(id) {
    return this.httpClient.getRequest(this.globalConst.categories + "/" + id);
  }

  categoryCreate(data) {
    return this.httpClient.postRequest(this.globalConst.categories, data);
  }

  categoryUpdate(category_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.categories + "/" + category_id,
      data
    );
  }

  categoryDelete(category_id) {
    return this.httpClient.deleteRequest(
      this.globalConst.categories + "/" + category_id,
      {}
    );
  }

  getIcons() {
    return this.httpClient.getRequest(
      this.globalConst.categories + "/icons",
      {}
    );
  }

  getStaff(page, query) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.users + "?page=" + page
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.users + "?search=" + query + "&page=" + page
      );
    }
  }

  getStaffById(id) {
    return this.httpClient.getRequest(this.globalConst.users + "/" + id);
  }

  staffCreate(data) {
    return this.httpClient.postRequest(this.globalConst.users, data);
  }

  staffUpdate(users_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.users + "/" + users_id,
      data
    );
  }

  staffDelete(users_id) {
    return this.httpClient.deleteRequest(
      this.globalConst.users + "/" + users_id,
      {}
    );
  }

  feedback(data) {
    return this.httpClient.getRequest(this.globalConst.feedback, data);
  }

  getOrders(from, to, order, page, query) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.orders +
          "?from=" +
          from +
          "&to=" +
          to +
          "&sort=" +
          order +
          "&page=" +
          page,
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.orders +
          "?from=" +
          from +
          "&to=" +
          to +
          "&sort=" +
          order +
          "&search=" +
          query  +
          "&page=" +
          page,
      );
    }
  }

  updateOrders(order_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.orders + "/" + order_id,
      data
    );
  }

  deleteOrders(index) {
    return this.httpClient.deleteRequest(
      this.globalConst.orders + "/" + index,
      {}
    );
  }

  //order tab
  getOrderNew(name) {
    return this.httpClient.getRequest(
      this.globalConst.orders + "/list/" + name,
      {}
    );
  }

  tabSubmit(order_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.orders + "/" + order_id,
      data
    );
  }

  getSalesOrder(from, to, order, page) {
    return this.httpClient.getRequest(
      this.globalConst.orders +
        "/sales?from=" +
        from +
        "&to=" +
        to +
        "&sort=" +
        order +
        "&page=" +
        page,
      {}
    );
  }

  
  getDashboard(from, to,type) {
    if(type == 'all'){
      return this.httpClient.getRequest(
        this.globalConst.dashboard
      )
    }else{
      return this.httpClient.getRequest(
        this.globalConst.dashboard + "?from=" + from + "&to=" + to
      );
    }
   
  }

  password(data) {
    return this.httpClient.postRequest(this.globalConst.password, data);
  }

  resetPassword(data) {
    return this.httpClient.putRequest(this.globalConst.password, data);
  }

  offlineSubmit(id) {
    return this.httpClient.putRequest(
      this.globalConst.orders + "/complete/" + id,
      {}
    );
  }

  //profile tab
  getProfile() {
    return this.httpClient.getRequest(this.globalConst.profile, {});
  }

  updateProfile(data) {
    return this.httpClient.postRequest(this.globalConst.profile, data);
  }

  // Offer module
  getOfferList(page, query) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.offers + "?page=" + page
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.offers + "?search=" + query + "&page=" + page
      );
    }
  }

  getOfferListFilter(id, page) {
    return this.httpClient.getRequest(
      this.globalConst.offers + "/all?category=" + id + "&page=" + page
    );
  }

  getOfferById(id) {
    return this.httpClient.getRequest(this.globalConst.offers + "/" + id);
  }

  offerCreate(data) {
    return this.httpClient.postRequest(this.globalConst.offers, data);
  }

  offerUpdate(offer_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.offers + "/" + offer_id,
      data
    );
  }

  offerStatusUpdate(offer_id, data) {
    return this.httpClient.patchRequest(
      this.globalConst.offers + "/" + offer_id,
      data
    );
  }

  offerDelete(offer_id) {
    return this.httpClient.deleteRequest(
      this.globalConst.offers + "/" + offer_id,
      {}
    );
  }
  // tables module
  getTableList(page, query) {
    if (query == "") {
      return this.httpClient.getRequest(
        this.globalConst.tables + "?page=" + page
      );
    } else {
      return this.httpClient.getRequest(
        this.globalConst.tables + "?search=" + query + "&page=" + page
      );
    }
  }

  getTableListFilter(id, page) {
    return this.httpClient.getRequest(
      this.globalConst.tables + "?filter=" + id + "&page=" + page
    );
  }

  getTables() {
    return this.httpClient.getRequest(
      this.globalConst.tables + "/dropdown"
    );
  }

  getTableById(id) {
    return this.httpClient.getRequest(this.globalConst.tables + "/" + id);
  }

  tableCreate(data) {
    return this.httpClient.postRequest(this.globalConst.tables, data);
  }

  tableUpdate(table_id, data) {
    return this.httpClient.putRequest(
      this.globalConst.tables + "/" + table_id,
      data
    );
  }

  tableDelete(table_id) {
    return this.httpClient.deleteRequest(
      this.globalConst.tables + "/" + table_id,
      {}
    );
  }

  fcm(data) {
    return this.httpClient.postRequest(this.globalConst.fcm, data);
  }
}


import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from '../core/Http/http-client-helper.service';
import { CitGlobalContantsService } from '../core/globalUrl/cit-global-contants.service';

@Injectable({
  providedIn: 'root'
})

export class LayoutService {
  constructor(
    private httpClient: HttpClientHelperService,
    private globalConst: CitGlobalContantsService
  ) {}


}

import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { HttpClientHelperService } from "../core/Http/http-client-helper.service";
import { AppService } from "../core/app.service";
import { BehaviorSubject } from "rxjs";
interface messageType {
  notification: any;
}
@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private messageSource: BehaviorSubject<messageType> = new BehaviorSubject(
    null
  );

  currentMessage = this.messageSource.asObservable();

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private http: HttpClientHelperService,
    private appService: AppService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.appService.fcm({ fcm: token }).subscribe((data) => {
          console.log(data);
        });
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload: messageType) => {
      console.log(payload);
      this.messageSource.next(payload);
    });
  }
}

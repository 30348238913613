import { Injectable } from '@angular/core';
import { BehaviorSubject,Subject } from 'rxjs';

@Injectable()
export class SharedService {
  constructor(){}
  //Using any
  public editDataDetails: any = [];
  public editProfile: any = [];
  public subject = new Subject<any>();
  public countSource = new  BehaviorSubject(this.editDataDetails);
  public profileImage = new  BehaviorSubject(this.editProfile);

  currentCount = this.countSource.asObservable();
  image = this.profileImage.asObservable();

  changeMessage(count: string) {
    this.countSource.next(count)
  } 
  
  changeImage(image: string) {
    this.profileImage.next(image)
  }
}
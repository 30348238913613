import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "./core/auth/auth.service";
import { LoaderService } from "./core/services/loader.service";
import { NotificationsService } from "./core/notifications.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "cafe";
  href = "";
  isLoading;
  isMessage: boolean = false;

  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private authService: AuthService,
    private NotificationsService: NotificationsService,
    private titleService: Title
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  logout() {
    // this.authService.logOut();
  }

  ngOnInit() {
    this.NotificationsService.requestPermission();
    this.NotificationsService.receiveMessage();
    this.NotificationsService.currentMessage.subscribe((message: any) => {
      console.log(message);
      if (message == null) {
        document.getElementById("push").style.display = "none";
      } else {
        document.getElementById("push").style.display = "block";
        document.getElementById("title").innerHTML = message.notification.title;
        document.getElementById("body").innerHTML = message.notification.body;
        document.getElementById("date").innerHTML =
          message.data["gcm.notification.time"];
        this.href = message.notification.click_action;
        setTimeout(function () {
          document.getElementById("push").style.display = "none";
        }, 4000);
      }
    });
  }
}

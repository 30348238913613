
import { ApiService } from '../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../core/auth/auth.service';
import { AppService } from '../core/app.service';
import { ToasterService } from '../core/services/toaster.service';
import { MustMatch } from '../core/must_validation';

@Component({ 
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
 })
export class ResetComponent implements OnInit {
  resetForm: FormGroup;
  isLoadingResults: boolean;
  success: boolean;
  submitted = false;
  email: string;
  token: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private notificationService: ToasterService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params.key;
      this.email = params.email;
      console.log(params);
   })
  }

  ngOnInit() {
    this.resetForm = this.formBuilder.group({
      email: [this.email, Validators.required],
      token: [ this.token, Validators.required],
      password:  ['', [Validators.required, Validators.minLength(8)]],
      password_confirmation: ['', Validators.required],
    }, {
       validator: MustMatch('password', 'password_confirmation')
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  onSubmit() {
    this.success = false;
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetForm.invalid) {
      return;
    }

    this.isLoadingResults = true;
    this.appService.resetPassword(this.resetForm.value)
      .subscribe(
        data => {
          this.isLoadingResults = false;
          this.success = true;
        },
        error => {
          this.notificationService.warn(error);
          this.isLoadingResults = false;
        });
  }
}


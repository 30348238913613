import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../auth/auth.service";
import { Role } from "../services/role";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // logged in so return true
      if (
        route.data.roles &&
        route.data.roles.indexOf(currentUser["data"].user.role) === -1
      ) {
        // role not authorised so redirect to home page
        if (currentUser["data"].user.role == Role.Manager) {
          this.router.navigate(["/"]);
        } else {
          this.router.navigate(["/manage-orders"]);
        }
        return false;
      }
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

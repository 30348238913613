


import { ApiService } from '../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthService } from '../core/auth/auth.service';
import { AppService } from '../core/app.service';
import { ToasterService } from '../core/services/toaster.service';

@Component({ 
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
 })
export class ForgotPasswordComponent implements OnInit {
  forgetForm: FormGroup;
  isLoadingResults: boolean;
  success: boolean;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private appService: AppService,
    private notificationService: ToasterService,
  ) {
   
  }

  ngOnInit() {
    this.forgetForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.forgetForm.controls; }

  onSubmit() {
    this.success = false;
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }

    this.isLoadingResults = true;
    this.appService.password({email:this.f.email.value})
      .subscribe(
        data => {
          this.isLoadingResults = false;
          this.success = true;
        },
        error => {
          this.notificationService.warn(error);
          this.isLoadingResults = false;
        });
  }
}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  baseUrl: "https://flax-api.scube.me",
  firebase: {
    apiKey: "AIzaSyD30AKL3JQgK2j4cwB_NwnDnQwjb5KAtZM",
    authDomain: "scube-push-testing.firebaseapp.com",
    databaseURL: "https://scube-push-testing.firebaseio.com",
    projectId: "scube-push-testing",
    storageBucket: "scube-push-testing.appspot.com",
    messagingSenderId: "467667117751",
    appId: "1:467667117751:web:f362e80b2a93ef8c7c0f32",
    measurementId: "G-DSD9L79G0Z",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError(err => {
            if (err.status === 403) {
                console.log(err)
                // auto logout if 401 response returned from api
                this.authenticationService.logout();
                location.reload(true);
            }

            const {errors} = err.error;
            const {message} = err.error;
            if(errors == undefined){
                var error =  message; 
            }else{
                    error =   errors[Object.keys(errors)[0]] || err.statusText;
            }

            return throwError(error);

        
        }))
    }
}
import { Component, OnInit } from '@angular/core';
import {Title, Meta} from '@angular/platform-browser';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './pagenotfound.component.html',
  styleUrls: ['./pagenotfound.component.scss']
})
export class PagenotfoundComponent implements OnInit {

  title = '404 - Page';

  constructor(
  	private titleService: Title,
  	) { }

  ngOnInit() {
  	this.titleService.setTitle(this.title);
  }

}

import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";

import { Role } from "../../core/services/role";
import { User } from "../../core/services/user.service";

import { LayoutService } from "../../layout/layout.service";
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent,
  PerfectScrollbarDirective,
} from "ngx-perfect-scrollbar";
import { SharedService } from "../../core/services/data.service";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

@HostListener("window:resize", ["$event"])
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  currentUser: any;
  userStatus;
  appData;
  img;
  constructor(
    public authService: AuthService,
    public layoutService: LayoutService,
    public sharedService: SharedService
  ) {
    this.authService.currentUser.subscribe((x) => (this.currentUser = x));
    console.log(this.currentUser);
  }

  // custom scroll
  public disabled = false;

  public config: PerfectScrollbarConfigInterface = {};

  // @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  // @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;

  // custom scroll end

  menuItems: any[];
  bodyClasses = "sidebar-mini";
  ngOnInit() {
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
  }

  get isManager() {
    return (
      this.currentUser && this.currentUser["data"].user.role === Role.Manager
    );
  }

  hideSidemenu() {
    let targetWidth = 768;
    const bodyClass = document.getElementsByClassName("sidebar-mini");
    const body = document.getElementsByTagName("body")[0];

    if (window.innerWidth <= targetWidth) {
      body.classList.remove("sidebar-mini");
      //Add your javascript for screens wider than or equal to 768 here
    }
  }

  logout() {
    this.authService.logout();
  }

  toggle() {
    const bodyClass = document.getElementsByClassName("sidebar-mini");
    const body = document.getElementsByTagName("body")[0];

    if (bodyClass.length === 0) {
      body.classList.add("sidebar-mini");
    } else {
      body.classList.remove("sidebar-mini");
    }
  }
}

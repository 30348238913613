import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import {
  MatAutocompleteModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
} from "@angular/material";

import { LoginComponent } from "./login/login.component";

import { DatePipe } from "@angular/common";

// App routing modules
import { AppRoutingModule } from "./app-routing/app-routing.module";
import { CommonModuleModule } from "./common-module/common-module.module";

// Guard services
import { AuthGuard } from "./core/guards/auth.guard";

// Auth services
import { AuthService } from "./core/auth/auth.service";
import { JwtInterceptor, ErrorInterceptor } from "./core/helpers";

import { LayoutComponent } from "./layout/layout/layout.component";

// Service file
import { HttpClientHelperService } from "./core/Http/http-client-helper.service";
import { CitGlobalContantsService } from "./core/globalUrl/cit-global-contants.service";
import { PagenotfoundComponent } from "./pagenotfound/pagenotfound.component";
import { SharedService } from "./core/services/data.service";

import { ForgotPasswordComponent } from "./forgotpassword/forgotpassword.component";
import { ResetComponent } from "./reset/reset.component";
import { ConfirmPopComponent  } from "./pages/confirmPop/confirmPop.component";

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from "../environments/environment";
import { NotificationsService } from "./core/notifications.service";

import { DaterangepickerModule } from "angular-2-daterangepicker";
import { SatDatepickerModule, SatNativeDateModule } from "saturn-datepicker";

import { MatDatepickerModule } from "@angular/material/datepicker";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LayoutComponent,
    ConfirmPopComponent ,
    PagenotfoundComponent,
    ForgotPasswordComponent,
    ResetComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    SatDatepickerModule,
    SatNativeDateModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonModuleModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    CitGlobalContantsService,
    HttpClientHelperService,
    NotificationsService,
    AuthGuard,
    AuthService,
    SharedService,
    DatePipe,
    MatDatepickerModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { map } from "rxjs/operators";
import { Config } from "protractor";

@Injectable()
export class HttpClientHelperService {
  constructor(private http: HttpClient) {}

  // HTTP-GET
  getRequest<T>(
    url: string,
    queryParams?: any,
    withAuthToken: boolean = true,
    defaultValue: T = null
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      params: null,
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    if (queryParams != null) {
      let httpParams = new HttpParams();
      Object.keys(queryParams).forEach(function (key) {
        httpParams = httpParams.append(key, queryParams[key]);
      });
      options.params = queryParams;
    }

    return this.http.get<T>(url, options);
  }

  // HTTP-GET
  getXMLRequest(
    url: string,
    queryParams?: HttpParams,
    withAuthToken: boolean = true,
    defaultValue: any = null
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({ "Content-Type": "text/xml" }),
      params: null,
      responseType: "text" as "text",
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    if (queryParams != null) {
      options.params = queryParams;
    }

    return this.http.get(url, options);
  }

  // HTTP-POST
  postJSONRequest<T>(
    url: string,
    body: any,
    withAuthToken: boolean = true,
    defaultValue: T = null
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      params: null,
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    return this.http.post<T>(url, body, options);
  }

  // HTTP-POST
  postRequest<T>(
    url: string,
    body: any,
    headers?: HttpHeaders,
    withAuthToken: boolean = true,
    defaultValue: T = null
  ): Observable<any> {
    const options = {
      headers: null,
    };

    if (headers != null) {
      options.headers = headers;
    } else {
      options.headers = new HttpHeaders();
    }

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
      options.headers = options.headers.append("x-auth-token", "");
    }

    return this.http.post<T>(url, body, options);
  }

  // HTTP-PUT
  putRequest<T>(
    url: string,
    body: any,
    queryParams?: HttpParams,
    withAuthToken: boolean = true,
    defaultValue: T = null
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: null,
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    if (queryParams != null) {
      options.params = queryParams;
    }

    return this.http.put<T>(url, body, options);
  }
  // HTTP-PATCH
  patchRequest<T>(
    url: string,
    body: any,
    queryParams?: HttpParams,
    withAuthToken: boolean = true,
    defaultValue: T = null
  ): Observable<any> {
    const options = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: null,
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    if (queryParams != null) {
      options.params = queryParams;
    }

    return this.http.patch<T>(url, body, options);
  }

  // HTTP-DELETE
  deleteRequest(
    url: string,
    body: any,
    withAuthToken: boolean = true
  ): Observable<object> {
    const options = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: null,
    };

    if (withAuthToken == false) {
      options.headers = options.headers.append("No-Authorization", "");
    }

    return this.http.delete(url, options);
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      this.log(`${operation} failed: ${error.errors}`);

      return of(result as T);
    };
  }

  private log(errors: string, data: any = null) {
    // console.log(`errors Log:: ${errors} ::`, data);
  }
}
